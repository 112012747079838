
import Vue from 'vue'
import Staff from '@/components/Staff.vue'

export default Vue.extend({
  components: {
    Staff
  },
  computed: {
    slides: function () {
      return this.$store.state.contactSlides;
    }
  }
})
